/* ==============================================================================
予約・来店・会計　管理モジュール

DB table R/W: visit、tech_slip、goods_slip
		 R  : staff、customer、salon
		 
 group: id,title,<staff>
 staff: id,no+name
 salesax: startDate, rate
 
 item:  id,group,   title,start_time,end_time,<visit>
 visit: id,staff_id,name, start_time,duration,visit_state,customer_id
 
=============================================================================== */

import React, { Component, useRef, useState, useEffect } from 'react';
import { Button,ButtonToolbar,ButtonGroup,InputGroup,InputGroupText,Input,FormGroup,
		 Modal,ModalHeader,ModalBody,ModalFooter,Tooltip, DropdownItem } from 'reactstrap';
// import { Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import Timeline, { TodayMarker,TimelineHeaders,DateHeader,SidebarHeader,CursorMarker } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import Select from 'react-select';
import { FaArrowLeft,FaArrowRight } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";

import DatePicker, { registerLocale,setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ja';
import './Table.css';
import './TimeLine.css';

import Request from 'superagent';
import { getMasters /* ,getRecord,addRecord,repRecords */ } from './Agent';
import {JWToken,LocalStorage,Alert,Confirm} from './auth/Login';
import ModalHook, {FooterOkCancel} from './ModalHook';
import Visitor from './Visitor';
import CashCount from './CashCount';			// 現金出納帳
import Stylist from './Stylist';
import HelpPopover from './HelpPopover';
import Attendance, {ModalNotifyEdit, CreateShift} from './Attendance';		// 勤怠管理
import MonthRoster from './MonthRoster';		// 月次勤怠印刷

import {
	MAX_DB_LENGTH,
	STATE_BOOK, STATE_VISIT, STATE_PAY, // STATE_SUM = 3;
	EDIT_UNSELECT, EDIT_SELECT, EDIT_ADD, EDIT_REP, EDIT_DEL,
	TIMEZONE,
	BOOK_VISIT, BOOK_WORK, BOOK_HOLIDAY,
//	WORK_OTHER			// 0:他店舗
//	WORK_REQUEST,		// 1:勤怠届
//	WORK_CREATE,		// 2:作成、シフト計画
//	WORK_RELEASE,		// 3:承認、シフト確定
//	WORK_ARRIVE,		// 4:出勤
//	WORK_LEAVE,			// 5:退勤
} from './Define';

const initialRow = {id:0, group:0 , title:"", className:"", start_time:0, end_time:0, techTotal:0, goodsTotal:0, visit:{id: 0, salon_id:0, visit_date: "", visit_time: "", regist_date:"", duration: 30, visit_state: STATE_BOOK, customer_id:0, name: "", staff_id: 0, credit_id:0, pay:0 } }
// const initialRow = {id:0, group:0 , title:"", className:"", start_time:0, end_time:0, techTotal:0, goodsTotal:0}; //, visit:{id: 0, salon_id: 0, staff_id: 0, name: "", visit_date: 0, visit_time: 0, duration: 30, visit_state: STATE_BOOK, customer_id:0 } };

const itemClassName = [ 'book', 'visit', 'pay', 'sum', 'net', 'cancel' ];		// 予約、来店、会計、集計、ネット予約、キャンセル
const itemColor     = [ 'rgb(0,0,0)', 'rgb(255,255,255)', 'rgb(255,255,255)' , 'rgb(255,255,255)', 'rgb(00,00,00)', 'rgb(00,00,00)'];
const itemBgColor   = ['lightcyan','SteelBlue','DarkBlue','dimgray','lemonchiffon','lavenderblush']; // [ 'rgb(207,226,243)', 'rgb(109,158,235)', 'rgb(07,55,99)' ];

const workClassName = [ 'other', 'request', 'create', 'release', 'arrive', 'leave'];
const workColor     = [ 'rgb(0,0,0)', 'rgb(0,0,0)', 'rgb(0,0,0)', 'rgb(255,255,255)', 'rgb(255,255,255)' , 'rgb(255,255,255)'];
const workBgColor   = ['white','honeydew','#CAE3BF','#5C7D57','#D3BD9F','#7D6C56']; 
//const workBgColor   = ['white','#CAE3BF','#A8C99B','#90B186','#D3BD9F','#7D6C56']; 
const workOffClassName = [ 'other', 'requestoff',   'createoff', 'releaseoff', 'arrive', 'leave'];
const workOffBgColor   = ['white',  'lavenderblush','#E18F9B',   '#E18F9B',' #C41A41','#BD425A']; 

const workTitle     = ['他店舗', '要望', '計画', '確定', '出勤', '退勤'];

// タイムゾーン
// const TIMEZONE = 'Asia/Tokyo';


const MASTER_SALON		= 0;
const MASTER_STAFF		= 1;
const MASTER_TECH		= 2;
const MASTER_GOODS		= 3;
const MASTER_CUSTOMER	= 4;
const MASTER_TAX		= 5;
const MASTER_POINT		= 6;
const MASTER_CREDIT		= 7;
const MASTER_HOLIDAYS	= 8;
const MASTER_DAYOFFITEM	= 9;

const masterParam	= [
	{ url: "/salon",		errMsg: "店舗一覧",		list: []	},
	{ url: "/staff",		errMsg: "担当者一覧",	list: [{id:0,nme:''}]	},
	{ url: "/tech",			errMsg: "技術一覧",		list: []	},
	{ url: "/goods",		errMsg: "商品一覧",		list: []	},
	{ url: "/customer",		errMsg: "顧客一覧",		list: []	},
	{ url: "/sales_tax",	errMsg: "消費税率一覧",	list: []	},
	{ url: "/point",		errMsg: "POINT率一覧",	list: []	},
	{ url: "/credit",		errMsg: "CREDIT一覧",	list: []	},
	{ url: "/holidays",		errMsg: "国民の休日",	list: []	},
	{ url: "/day_off_item",	errMsg: "勤怠届一覧",	list: [{id:0,name:''}]	},
];

// スタイリストと予約情報を取得する
const DATA_STYLIST	= 0x01;
const DATA_VISIT    = 0x02;
const DATA_WORKHOUR = 0x04;
const DATA_ALL      = DATA_STYLIST | DATA_VISIT | DATA_WORKHOUR;

// var rowProp = Object.assign({},initialRow);
var editStatus = EDIT_UNSELECT;

// 現在読み込んだvisitの範囲
var tableItemStart	= moment().tz(TIMEZONE).format('YYYY-MM-DD');
var tableItemEnd	= moment().tz(TIMEZONE).format('YYYY-MM-DD');

var stylist   = [];			// スタイリスト
var visitList = [];			// 予約データ
var techSlip  = [];			// 技術会計
var goodsSlip = [];			// 商品会計
var workhourList  = [];		// 勤怠データ
var holidays = [];			// 国民の休日

// var item_flag = 0;			// DBのRead終了をすべて待ってから実行する
var data_flag = 0;			// stylistとvisit をReadしてから表示処理を行う
var	salon_id = 0;			// 現在選択されている店舗のid
var salon = {};				// 現在選択されている店舗

// default format object
var headerFormats = {
	year: {
	    "long": 'YYYY',
	    mediumLong: 'YYYY',
	    medium: 'YYYY',
	    "short": 'YY'
	},
	month: {
		"long": 'YYYY年 MM月',
		mediumLong: 'MMM',
		medium: 'MMM',
		"short": 'YY/MM'
	},
	week: {
		"long": 'w',
		mediumLong: 'w',
		medium: 'w',
		"short": 'w'
	},
	day: {
		"long": 'LL dddd',
		mediumLong: 'DD日 dd',
		medium: 'D dd',
		"short": 'D'
	},
	hour: {
		"long": 'HH:00',
		mediumLong: 'HH:00',
		medium: 'HH時',
		"short": 'HH'
	},
	minute: {
		"long": 'HH:mm',
		mediumLong: 'HH:mm',
		medium: 'HH:mm',
		"short": 'mm'
	}
};

function taxRate( day) {
	let tax = 0;
	let taxList = masterParam[MASTER_TAX].list;
	for ( let i = 0 ; i < taxList.length ; i++ ) {
		if ( taxList[i].start > day ) break;
		tax = taxList[i].rate;
	}
	return 1 + tax/100;
}

function pointRate( day) {
	let rate = 0;
	let pointList = masterParam[MASTER_POINT].list;
	for ( let i = 0 ; i < pointList.length ; i++ ) {
		if ( pointList[i].start > day ) break;
		rate = pointList[i].rate;
	}
	return rate/100;
}

function yenFormat(num){
	if ( num === null ) return '';
	if ( num === 0 )    return '';
	if ( isNaN(num) )   return '';
	if ( num < 0 ) {
		let yen = Math.round(num);
		return '-' + String(yen).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
	}
	let yen = Math.round(num);
    return String(yen).split("").reverse().join("").match(/\d{1,3}/g).join(",").split("").reverse().join("");
}

// 税込売上
function amount(item) {
	let total = (item.techTotal + item.goodsTotal) * taxRate(item.visit.visit_date) - item.visit.use_point;
	return Math.floor(total/10) * 10;
}

// ポイント発行
function addPoint(item) {
	item.visit.add_point = Math.floor((item.techTotal + item.goodsTotal - item.visit.use_point) * pointRate(item.visit.visit_date));
}



// ====================================================================================================
// レジ担当者の項目にフォーカスする
// 
// ====================================================================================================
const SelectStaff = (props) => {
	const inputElement = useRef(null);
	
	useEffect(() => {
		if (inputElement.current) {
			inputElement.current.focus();
		}
	}, []);
	
	return (
		<Select className="form-control react-select-original" placeholder="担当者名（必須）" value={props.staffValue} options={props.options} onChange={props.onChange}  ref={inputElement}
			menuPortalTarget={document.body} 
    		styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
	);
};

/* =============================================================================
 * onItemMove() 時に表示するダイアログ
 *  移動やリサイズを確認して、編集したstaffを入力する
 *  シフト管理はどうするか？  別の画面を表示するべき。
 * =============================================================================*/
const ModalMoveBook = (props) => {
	const {
		modal,
		dragTime,		// 移動先の日次
		item,			// 移動するitem
		staffList,		// 担当者一覧
		repVisit,		// 来店情報のReplaceと来店情報の再読み込み
	} = props;

	const [disabled,	setDisabled   ] = useState(true);	

    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる
		setDisabled(true);
    }, [modal]);

	// レジ担当者を変更した
	const opeStaffChange = (option) =>  {
		item.visit.ope_staff = Number(option.value);
		setDisabled(false);
	};

	// スタッフの設定
	let list = staffList.filter( (staff) => {		// 削除された番号は選択出来ない
		return staff.no < MAX_DB_LENGTH;
	});
	let options = list.map( (staff) => {
		return { value: staff.id, label:staff.no + ' ' + staff.name};
	});
	let opeStaffValue = { value: '', label:''};

	// DBを更新してcloseする
	const repClose = () => {
		repVisit(item, dragTime );
	};
	// DBを更新せずにcloseする
	const close = () => {
		repVisit(null,null);
	};
	
	let subTitle = item.visit.name;

	let body =
		<div>
			<FormGroup disabled>
				<InputGroup>
					<InputGroupText style={{width:'90px'}}>レジ担当　　</InputGroupText>
					<SelectStaff className="form-control react-select-original" placeholder="担当者名" value={opeStaffValue} options={options} onChange={opeStaffChange} autoFocus />
				</InputGroup>
			</FormGroup>
			変更前：{ item.visit.visit_date + ' ' + item.visit.visit_time.substr( 0, 5 ) }<br/>
			変更前：{ moment(dragTime).format('YYYY-MM-DD HH:mm')  }<br/>
			変更してよろしいですか？
		</div>;

	return (
		<ModalHook isOpen={modal} title={'予約時間の変更'} subTitle={subTitle} body={body} maxWidth = '500px' onCancel={close} 
        	footer={<FooterOkCancel onCancel={close} onClose={repClose} disabled={disabled} />} />
	);
};

const defaultVisit = {id: 0, salon_id:0, visit_date: "", visit_time: "", regist_date:"", duration: 30, visit_state: STATE_BOOK, customer_id:0, name: "", staff_id: 0, credit_id:0, pay:0 };
const defaultWork  = {tenant_id: 0, salon_id: 0, staff_id: 0, work_date: "2023-01-25", day_off_id: 0, status: 0, shift_start: "09:30", shift_quit: "17:00", punch_start: '', punch_quit: '' };

/* =================================================================================================
 * Booking 予約管理画面
 *  ・予約管理
 *  ・シフト管理
 *  ・勤怠管理
 * ================================================================================================= */
class Booking extends Component {
/***
const Booking = (props) => {
	const {
	} = props;
	
	const [groups,		setGroups   ] = useState([{id:0, title:"", staff: {id: 0, no:0, name: "", mail: "", phone: ""} }]);	
	const [items,		setItems	] = useState([{id:0, group:0 , title:"", className:"", start_time:0, end_time:0, techTotal:0, goodsTotal:0, visit:defaultVisit, workhour: defaultWork }]);	
	const [rowData,		setRowData	] = useState( {id:0, group:0 , title:"", className:"", start_time:0, end_time:0, techTotal:0, goodsTotal:0, visit:defaultVisit, workhour: defaultWork } );	
	const [showModal,	setShowModal] = useState( false);	
	const [viewDate,	setViewDate	] = useState( moment().tz(TIMEZONE).format('YYYY-MM-DD') );	
	const [visibleTimeStart,setVisibleTimeStart	] = useState( moment().tz(TIMEZONE).add(-3,'hour').valueOf() );	
	const [visibleTimeEnd,	setVisibleTimeEnd	] = useState( moment().tz(TIMEZONE).add( 5,'hour').valueOf() );	

	const [techSlipList,	setTechSlipList	] = useState( [] );	// 技術売上
	const [goodsSlipList,	setGoodsSlipList] = useState( [] );	// 商品売上
	const [workhour,		setWorkhour		] = useState( [] );	// 勤怠データ
	
	const [salonList,		setSalonList		] = useState( [] );	// 店舗一覧
	const [customerList,	setCustomerList		] = useState( [] );	// 顧客マスター
	const [techList,		setTechList			] = useState( [] );	// 技術マスター
	const [goodsList,		setGoodsList		] = useState( [] );	// 商品マスター
	const [staffList,		setStaffList		] = useState( [] );	// 担当者マスター
	const [creditList,		setCreditList		] = useState( [] );	// クレジット
	const [dayOffItemList,	setDayOffItemList	] = useState( [{id:0}] );	// 勤怠届マスター

	const [workhour,		setWorkhour		] = useState( [] );	// 勤怠データ

	const [stepDays,		setStepDays		] = useState( 1	);		// 次の日のボタンが押された時に何日次にいくか？
	const [tooltipOpen,		setTooltipOpen	] = useState( false );	// 日付のツールチップ
	const [dragTime,		setDragTime		] = useState( null	);	// onItemMove() 時にModalMoveBookに渡す為のデータ
	const [showModalMoveBook,	setShowModalMoveBook	] = useState( false );		// ModalMoveBookの表示・非表示
	const [showModalNotifyEdit,	setShowModalNotifyEdit	] = useState( false );		// Attendance.jsのModalNotifyEdit画面の表示・非表示

    useEffect(() => {       // componentDidMount() の代わり。 最初に1度だけコールされる
		this.sumPoint();				// ポイントを顧客マスターに加算する
		this.delVisitLog();				// 集計が終了した Visit Log の削除
		
		getMasters(masterParam,function () {
					this.getInitialVisitTable();		// group,itemの作成
		}.bind(this));
    }, []);


***/
	// コンストラクター
	constructor(props) {
		super(props);

		this.state = {
			groups: [ {id:0, title:"", staff: {id: 0, no:0, name: "", mail: "", phone: ""} } ],
			items:  [ {id:0, group:0 , title:"", className:"", start_time:0, end_time:0, techTotal:0, goodsTotal:0, visit:defaultVisit, workhour: defaultWork }],
			rowData:  {id:0, group:0 , title:"", className:"", start_time:0, end_time:0, techTotal:0, goodsTotal:0, visit:defaultVisit, workhour: defaultWork },	// id:customer_id, groups:staff_id
			showModal: false,
			viewDate:			moment().tz(TIMEZONE).format('YYYY-MM-DD'),		// 表示する日
			visibleTimeStart:	moment().tz(TIMEZONE).add(-3,'hour').valueOf(),
			visibleTimeEnd: 	moment().tz(TIMEZONE).add( 5,'hour').valueOf(),

			techSlipList: [],	// 技術売上
			goodsSlipList: [],	// 商品売上
			workhour: [],		// 勤怠データ

			salonList: [],		// 店舗一覧
			customerList: [],	// 顧客マスター
			techList: [],		// 技術マスター
			goodsList: [],		// 商品マスター
			staffList: [],		// 担当者マスター
			creditList: [],		// クレジット
			dayOffItemList: [{id:0}],	// 勤怠届マスター

			stepDays: 1,		// 次の日のボタンが押された時に何日次にいくか？	
			tooltipOpen: false,
			dragTime: null,		// onItemMove() 時にModalMoveBookに渡す為のデータ
			showModalMoveBook: false,		// ModalMoveBookの表示・非表示
			showModalNotifyEdit:false,		// Attendance.jsのModalNotifyEdit画面の表示・非表示
			showModalStylist:false,			// スタイリスト編集
			showModalRoster:false,			// 月次勤怠集計表の印刷
		};

//		item_flag =0;			// 読み込んだitemを管理する

		moment.locale('ja');
		moment.tz.setDefault(TIMEZONE);		// timezoneをJSTに変更する

		tableItemStart = moment().tz(TIMEZONE).format('YYYY-MM-DD');
		tableItemEnd   = moment().tz(TIMEZONE).format('YYYY-MM-DD');
// console.log(tableItemStart);
		registerLocale('ja', ja);		// react-datepicker の日本語表記
		setDefaultLocale('ja');
	}

	// ComponentがDOMツリーに追加される前に一度だけ呼ばれる。
	componentDidMount = () => {
//		item_flag =0;
		this.sumPoint();				// ポイントを顧客マスターに加算する
		this.delVisitLog();				// 集計が終了した Visit Log の削除
		
		getMasters(masterParam,function () {
					this.getInitialVisitTable();		// group,itemの作成
		}.bind(this));

	}


// item.title の更新が遅れる。何か別の操作をしないと更新されないのを解決する。
// Item Context Title does not match item title #519
    shouldComponentUpdate(nextProps, nextState) {
		if ( this.state.items !== nextState.items ) {
			this.setState({items: [...nextState.items] });
            setTimeout(() => this.forceUpdate(), 0);
		}
		return true;
	}
// Item Context Title does not match item title #519

// ======================================================================================
// DB 入出力 関数
// ======================================================================================

/* -----------------------------------------------------------
	マスターの取得
------------------------------------------------------------- */
	getInitialVisitTable = () => {

		// 国民の休日
		holidays = masterParam[MASTER_HOLIDAYS].list.map(function(value, index) {  return new Date(value.day); });

		// ローカルストレージからsalon_idを取得する
		salon_id = LocalStorage.getItem().salon_id;
		salon = masterParam[MASTER_SALON].list.find((s) => s.id === salon_id);
		if ( salon === undefined ) {
			salon = masterParam[MASTER_SALON].list[0];		// 店舗の初期値を設定する
			salon_id = salon.id;

			let storeItem = LocalStorage.getItem();
			storeItem.salon_id = salon_id;
			LocalStorage.setItem(storeItem);
		}
console.log(LocalStorage.getItem());		
		this.setState({
			salonList:	masterParam[MASTER_SALON].list,
			staffList:	masterParam[MASTER_STAFF].list,
			techList:	masterParam[MASTER_TECH].list,
			goodsList:	masterParam[MASTER_GOODS].list,
			taxList:	masterParam[MASTER_TAX].list,
			pointList:	masterParam[MASTER_POINT].list,
			creditList:	masterParam[MASTER_CREDIT].list,
			customerList:	masterParam[MASTER_CUSTOMER].list,
			dayOffItemList: masterParam[MASTER_DAYOFFITEM].list,
		});
		this.getStylist(salon_id);
		
		this.changeDate(moment().tz(TIMEZONE));
/****
		let start = moment().tz(TIMEZONE).add(-7,'days').format('YYYY-MM-DD');
		let end   = moment().tz(TIMEZONE).add( 7,'days').format('YYYY-MM-DD');
//		tableItemStart = tableItemEnd = moment().tz(TIMEZONE).format('YYYY-MM-DD');
		this.getVisitTable(start,end,true);		// 前後1週間分の予約・勤怠データを読み込む
****/
	}


/* -----------------------------------------------------------
	visitのポイントをcustomerのポイントに加算する
	// 昨日以前のポイントを顧客に加算する
------------------------------------------------------------- */
	sumPoint = () => {
		//ajax通信する
		Request.get("/visit/point/" )
			.query( { token: JWToken.getToken().token } )
			.end(function(err, res) {
				if (err) {
					console.error("Visitポイントをcustomerに加算できません");
					console.log(err,err.status);
				}
			});
	}
	
/* -----------------------------------------------------------
	昨日以前のVisitLogの削除
------------------------------------------------------------- */
	delVisitLog = () => {
		//ajax通信する
		Request.delete("/visit_log/" )
			.query( { token: JWToken.getToken().token } )
			.then( res => {
			})
			.catch( err => {
				console.log(err,err.status);
				console.error("Visit Logを削除できませんでした。");
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}
	
/* -----------------------------------------------------------
	スタイリスト取得
   -----------------------------------------------------------
	店舗情報を読込後にsalon_idが決定してからスタイリストを取得する
------------------------------------------------------------- */
	getStylist =  (salon_id) => {
		
		data_flag &= ~DATA_STYLIST;
		Request.get("/stylist/" + salon_id )
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				stylist = res.body.dbData;
				data_flag |= DATA_STYLIST;
				this.createItem();		// item,groupの作成
			})
			.catch( err => {
				console.log(err,err.status);
				console.error("スタイリストを取得できませんでした。");
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	// スタイリスト一覧を登録する
	putStylistList = (salon_id) => {
		//ajax通信する
		Request.put('/stylist/' + salon_id )
			.send({ dbData:stylist, token: JWToken.getToken().token } )
			.then( res => {
				this.getStylist(salon_id);
			})
			.catch( err => {
				console.log(err,err.status);
				console.error("スタイリストを登録できませんでした");
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}


/* -----------------------------------------------------------
	来店情報取得
   -----------------------------------------------------------
	・取得する日時の開始ー終了を指定する。
	・高速化のために、既に読み込まれていたら再読み込みは行わない。
	・データ登録後などは、既に読み込まれていても再読み込みを行う。
	・再読み込みを行うケース。データ登録前はvisit.idを0に設定している。
			DBに登録後にvisit.idが決まる。(item.id == visit.id)
------------------------------------------------------------- */
	getVisitTable = (viewStart,viewEnd,fourced=false) => {

		let readStart = viewStart;			// read開始日
		let readEnd   = viewEnd;			// read終了日

		if ( fourced ) {
			// 表示範囲と読込範囲、テーブル範囲は同じ
			tableItemStart = viewStart;
			tableItemEnd   = viewEnd;
		} else {
			if ( viewStart >= tableItemStart && viewEnd <= tableItemEnd  ) return;		// 既に読み込み済み 何もしない
			
			if ( viewStart <  tableItemStart && viewEnd >  tableItemEnd ) {				// strat,end が共に範囲を超えたなら
				readStart  =  tableItemStart = viewStart;								// 縮小したので、再読み込み
				readEnd	   =  tableItemEnd   = viewEnd;
				fourced = true;
			}

			if ( viewStart < tableItemStart ) {
				readEnd   = moment(tableItemStart).add(-1,'days').format('YYYY-MM-DD');
				readStart = tableItemStart = viewStart;
			}	
			if ( viewEnd   > tableItemEnd   ) {
				readStart = moment(tableItemEnd  ).add( 1,'days').format('YYYY-MM-DD');
				readEnd   = tableItemEnd   = viewEnd;
			}
		}

		// 勤怠データーの読込
		data_flag &= ~DATA_WORKHOUR;
		Request.get("/workhour/booking/" + readStart+'_'+ readEnd)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				if ( fourced === true ) {
					workhourList = res.body.dbData;
				} else {
					workhourList = workhourList.concat(res.body.dbData);
				}
				data_flag |= DATA_WORKHOUR;
				this.createItem();		// item,groupの作成
			})
			.catch( err => {
				console.error("勤怠データを取得できませんでした。");
				if ( err ) console.log(err.status);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});

/*************************************
		// 予約データーの読込
		data_flag &= ~DATA_VISIT;
		let result = getTableAsync("/visit/edit/" + salon_id + "/" + readStart+'_'+ readEnd, '予約データ');
		if ( fourced === true ) {
			visitList = result;
		} else {
			visitList = visitList.concat(result);
		}
		data_flag |= DATA_VISIT;
*************************************/

/*************************************/
		// 予約データーの読込
		data_flag &= ~DATA_VISIT;
		Request.get("/visit/edit/" + salon_id + "/" + readStart+'_'+ readEnd)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				if ( fourced === true ) {
					visitList = res.body.dbData;
				} else {
					visitList = visitList.concat(res.body.dbData);
				}
				data_flag |= DATA_VISIT;
				this.createItem();		// item,groupの作成
			})
			.catch( err => {
				console.error("予約データを取得できませんでした。");
				if ( err ) console.log(err.status);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
/*************************************/

	}


	// 来店情報のReplace・技術売上の書き込み・来店情報の再読み込み
	putVisit = (item) => {
		let visit = item.visit;
		Request.put('/visit/' + visit.id )
			.send({ dbData: visit, token: JWToken.getToken().token } )
			.then( res => {
				this.putTechSlip (visit.id, this.state.techSlipList);	// 技術売上のDB登録
				this.putGoodsSlip(visit.id, this.state.goodsSlipList);	// 商品売上のDB登録

				this.postLog(item,this.state.techSlipList,this.state.goodsSlipList);		// ログ出力

				this.getVisitTable(tableItemStart,tableItemEnd,true); 	// 強制再読み込み
//				this.onItemSelect(item.id);		// 2022-12-22 Close時にitem.titleが描き変わらないので再読み込み
			})
			.catch( err => {
				console.log(err);
				console.err("来店情報を修正できませんでした");
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	
	// 来店情報のAdd
	postVisit = (item) => {
		let visit = item.visit;
		Request.post('/visit')
			.send({ dbData: visit, token: JWToken.getToken().token } )
			.then( res => {
				// DBに登録した来店idを売上情報に設定する
				let id = res.body.dbData[0].id;
				let tech = this.state.techSlipList.map(function(value, index) {  value.visit_id = id; return value; });
				this.putTechSlip(id,tech);			// 技術売上のDB登録
				
				let goods = this.state.goodsSlipList.map(function(value, index) {  value.visit_id = id; return value; });
				this.putGoodsSlip(id,goods);		// 商品売上のDB登録

				item.visit.id = id;
				this.postLog(item,tech,goods);		// ログ出力

				this.getVisitTable(tableItemStart,tableItemEnd,true);  // 強制再読み込み
			})
			.catch( err => {
				console.error("予約を追加できませんでした");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	// 来店情報、技術売上、商品売上を削除する
	deleteVisit = (visit_id) => {
		Request.del('/visit/' + visit_id )
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				this.getVisitTable(tableItemStart,tableItemEnd,true);  // 強制再読み込み
			})
			.catch( err => {
				console.error("予約・来店情報が削除できませんでした。");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

/* -----------------------------------------------------------
	技術売上情報の取得
	・技術売上情報は予約・来店情報１つ分を読み書きする
	・
------------------------------------------------------------- */
	getTechSlip = (visit_id) => {
		//ajax通信する
		Request.get("/tech_slip/edit/" + visit_id)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				// 技術表示
				techSlip = res.body.dbData;
				// 技術合計金額
				let techTotal = 0;
				techSlip.forEach(function(val){
					techTotal += val.price;
				});
				let item = this.state.rowData;
				item.techTotal = techTotal;
				this.setState({ rowData: item,
								techSlipList: techSlip
				});
				this.getVisitTable(tableItemStart,tableItemEnd,true);  // 強制再読み込み
			})
			.catch( err => {
				console.error("技術売上一覧を取得できませんでした。");
				if ( err ) console.log(err.status);
				if ( err & err.status === 403 ) JWToken.clearToken();
				this.setState({techSlipList: [] });		// 技術売を初期化
			});
	}


	/* ----------------------------------------------
	   技術売上情報の登録
	---------------------------------------------- */
	putTechSlip = (visit_id,slip) => {
		//ajax通信する
//		if ( slip.length === 0 ) return;		// 登録されているデータを削除する場合
		Request.put("/tech_slip/" + visit_id)
			.send( { dbData: slip, token: JWToken.getToken().token } )
			.then( res => {
				
			})
			.catch( err => {
				console.error("技術売上一覧を登録できませんでした。");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	deleteTechSlip = (visit_id) => {
		Request.del('/tech_slip/' + visit_id )
			.query( { token: JWToken.getToken().token } )
			.catch( err => {
				console.error("技術売上一覧を削除できませんでした。");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

/* -----------------------------------------------------------
	商品売上情報の取得
	・商品売上情報は予約・来店情報１つ分を読み書きする
	・
------------------------------------------------------------- */
	getGoodsSlip = (visit_id) => {
		//ajax通信する
		Request.get("/goods_slip/edit/" + visit_id)
			.query( { token: JWToken.getToken().token } )
			.then( res => {
				// 商品表示
				goodsSlip = res.body.dbData;

				// 商品合計金額
				let goodsTotal = 0;
				goodsSlip.forEach(function(val){
					goodsTotal += val.price;
				});
				let item = this.state.rowData;
				item.goodsTotal = goodsTotal;
				this.setState({ rowData: item,
								goodsSlipList: goodsSlip
				});
			})
			.catch( err => {
				console.error("商品売上一覧を取得できませんでした。");
				if ( err ) console.log(err.status);
				if ( err & err.status === 403 ) JWToken.clearToken();
				this.setState({goodsSlipList: [] });		// 技術売上を初期化
			});
	}

	/* ----------------------------------------------
	   商品売上情報の登録
	---------------------------------------------- */
	putGoodsSlip = (visit_id,slip) => {
		//ajax通信する
//		if ( slip.length === 0 ) return;		// 登録されているデータを削除する場合
		Request.put("/goods_slip/" + visit_id)
			.send( { dbData: slip, token: JWToken.getToken().token } )
			.catch( err => {
				console.error("商品売上一覧を登録できませんでした。");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	deleteGoodsSlip = (visit_id) => {
		Request.del('/goods_slip/' + visit_id )
			.query( { token: JWToken.getToken().token } )
//			.end(function(err, res){
			.catch( err => {
				console.error("商品売上一覧を削除できませんでした。");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	/* ----------------------------------------------
	   来店情報のログを登録
	---------------------------------------------- */
	getLog = (visit_id) => {
		
		Request.get("/visit_log/" + visit_id )
			.query( { token: JWToken.getToken().token } )
			.then( res => {
// 				console.log(res.body.dbData);
				return res.body.dbData;
			})
			.catch( err => {
				console.log(err,err.status);
				console.error("来店情報ログを取得できませんでした。");
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	// 来店情報のAdd
	postLog = (item,techSlipList,goodsSlipList) => {

		let log = {
			visit_id:	item.visit.id,
			staff_id:	1,							// レジ担当者
			edit_time:	moment().format("YYYY-MM-DD HH:mm:ss"),
			visit:		item.visit,
			tech_slip:	techSlipList,
			goods_slip:	goodsSlipList
		};
//		console.log(log);

		Request.post('/visit_log')
			.send({ dbData: log, token: JWToken.getToken().token } )
			.then( res => {
//				console.log(log);
			})
			.catch( err => {
				console.error("来店情報ログを追加できませんでした");
				console.log(err);
				if ( err & err.status === 403 ) JWToken.clearToken();
			});
	}

	/* ----------------------------------------------
	   顧客情報の登録
	---------------------------------------------- */
	putCustomer = (cust,techSlipList,goodsSlipList) => {
		delete cust.sumName;

		let index = this.state.customerList.findIndex((obj) => { return obj.id === cust.id });
		let customerList = this.state.customerList;
		customerList[index] = cust;
		this.setState({customerList:customerList});
		
		let item = this.state.rowData;
		item.visit.name        = cust.name;
		item.title             = cust.name;			// 顧客名をCalenderTimelineに表示する
		item.visit.customer_id = cust.id;
		item.visit.staff_id    = cust.staff_id;		// 顧客の担当者に変更する
		item.customer_no       = cust.no;
		if ( item.visit.use_point > cust.point ) {
			item.visit.use_point   = 0;				// 顧客が変わったので、利用ポイントを０にする
		}
		item.point   = cust.point;					// point は顧客にあるポイントを設定する
		this.setState({
			item: item,
			techSlipList  :techSlipList,
			goodsSlipList :goodsSlipList
		});
		
		Request.put('/customer/' + cust.id )
			.send({ dbData:cust, token: JWToken.getToken().token } )
			.then(res => {
//				this.getList(customerParam);	// 顧客マスター  日付が変わってしまうのでコメントにした 2020-09-05
			})
			.catch( err => {
					console.log(err);
					console.error("顧客を登録できませんでした");
			});
	}


	/* ----------------------------------------------
	// Attendance が使用するコールバック Bookingの情報をAttendance渡す
	---------------------------------------------- */
	getBookInfo = () => {
		let storeItem = LocalStorage.getItem();			// 本体に保存している店舗ID

		return { 
			date:		this.state.viewDate,			// 今表示している日付
			salon_id:	storeItem.salon_id,				// 今表示している店舗
		};
	};
	
	
	
// ======================================================================================
// item の読込管理 担当者、顧客、勤怠、予約を全て読み込んでから
// ======================================================================================
	createItem = () => {

		// stylist と visit をReadしてない場合は、return
		if ( data_flag !== DATA_ALL ) return;

		// salonを特定する
		let salon = masterParam[MASTER_SALON].list.find((s) => s.id === salon_id);

		// stylist から group情報を作成して表示
		let groups = [];

		let group = {};			// 予約
		group.id    = 0;
		group.title =  "指名なし";
/* *　/
						<CreateShift name={'指名なし'}
									 reload={this.changePeriod} 
									 salon={salon}
									 getBookInfo={this.getBookInfo}
									 
						/>;		// スタイリスト指定なし
/　* */
		groups.push(group);

		// groupsの作成
		stylist.forEach(function(value, index) {			
			let group = {};			// 予約
			group.id    = value.staff_id;
			try {
				group.title = 
						<Attendance id={value.staff_id}
									name={value.name}
									reload={this.changeDate}
									dayOffItem={this.state.dayOffItemList} 
									salon={salon}
									getBookInfo={this.getBookInfo}
						/>;
			} catch (error) {
				console.log(error);
			}
			groups.push(group);
		}.bind(this));

		// 予約(visit) から item情報を作成
		let items = visitList.map(function(value, index) {
			let item = Object.assign({},initialRow);
			let visit  = Object.assign({},value);
			item.id		= visit.id;

//			item.group	= visit.staff_id;				
			let group = groups.find(group => group.id === visit.staff_id);
			if (group === undefined ) { item.group = 0; }		// スタイリスト指名なし
			else					  { item.group = group.id; }
			
			item.bookType   = BOOK_VISIT;
			item.title	    = visit.name + ( visit.nickname === null ? '' : ' 【' + visit.nickname + '】 ' )	// 技術略称を追加する
										 + ( visit.memo === null ? '' : visit.memo.replace(/\\n/g, ' ') );		// メモを追加する
			item.start_time = moment(visit.visit_date+' '+visit.visit_time).valueOf();
			item.end_time	= item.start_time + (visit.duration * 60000 );
			item.className  = itemClassName[visit.visit_state];
			item.color      = itemColor[visit.visit_state];
			item.bgColor    = itemBgColor[visit.visit_state];
			item.visit_time	= moment(item.start_time).format('YYYY-MM-DD HH:mm');
			item.pay        = yenFormat(visit.pay);
		//  item ✖ 顧客数を全て検索すると時間がかかる？ → この処理は表示する時に行う
		//	item.customer_no= customers.find(function(obj){ return obj.id == value.customer_id; }).no;
			item.customer_no='';
			
			// メモの改行を処理する
			if ( visit.memo !== null && visit.memo !== undefined ) {
				visit.memo = visit.memo.replace(/\\n/g,'\n');		// reactは'\n'の処理を行ってくれない？ので自分で改行した
			}

			item.visit	= visit;
			item.workhour = defaultWork;
			return item;
		});

		// 国民の休日からitem情報を作成
		let itemHoliday = masterParam[MASTER_HOLIDAYS].list.map(function(value,index) {
			let item	= Object.assign({},initialRow);
			item.id         = 'h' + index;						// item.idが0だとonItemSelect、onItemClickに反応しない。
			item.group      = 0;

			item.bookType   = BOOK_HOLIDAY;
			item.title      = value.name;
			item.start_time = moment(value.day + ' ' + salon.stime).valueOf();
			item.end_time	= moment(value.day + ' ' + salon.etime).valueOf();
			item.className  = 'timeoff';
			item.color      = 'rgb(00,00,00)';
			item.bgColor    = 'lavenderblush';		// 休日
			item.canMove    = false;
			item.canResize  = false;
			item.canChangeGroup= false;
			return  item;
		});

		// workhour から item情報を作成
		let itemWorks = workhourList.map(function(value, index) {
			let item	= Object.assign({},initialRow);
			item.id		= 'w' + index;

//			item.group	= visit.staff_id;				
			let group = groups.find(group => group.id === value.staff_id);
			if (group === undefined ) { item.group = 0; }		// 表示しない
			else					  { item.group = group.id; }
			item.bookType   = BOOK_WORK;
			item.title	    = value.name + ':' + workTitle[value.status] + ' ' + (value.abstract || ""); 	// 届出を表示
			item.start_time = moment(value.work_date+' '+ ( value.start || value.shift_start) || salon.stime ).valueOf();
			item.end_time	= moment(value.work_date+' '+ ( value.quit  || value.shift_quit || salon.etime ) ).valueOf();
			if ( value.start ) item.start_time = moment(value.work_date+' '+value.start).valueOf();
			if ( value.quit )  item.end_time   = moment(value.work_date+' '+value.quit ).valueOf();

			// 勤務: シフト、出勤、退勤、休みによって色を変える
			if ( !value.on_off ) {
				item.className  = workOffClassName[value.status];
				item.color      = workColor[value.status];
				item.bgColor    = workOffBgColor[value.status];
			} else if ( value.salon_id === salon_id) {
				item.className  = workClassName[value.status];
				item.color      = workColor[value.status];
				item.bgColor    = workBgColor[value.status];
			} else {
				item.title	    = workTitle[value.status] + ':他店舗 ' + value.name + ' ' + (value.abstract || ""); 	// 届出を表示
				item.className  = 'other';
				item.color      = 'rgb(0,0,0)';
				item.bgColor    = 'white';
			}
//			item.selectedBgColor = value.on_off? 'honeydew' : 'mistyrose';
			item.canMove    = false;
			item.canResize  = false;
			item.canChangeGroup = false;
//			item.disableInteraction = true;
		//	item.visit_time	= moment(item.start_time).format('YYYY-MM-DD HH:mm');
		//	item.pay        = yenFormat(visit.pay);
		//  item ✖ 顧客数を全て検索すると時間がかかる？ → この処理は表示する時に行う
		//	item.customer_no= customers.find(function(obj){ return obj.id == value.customer_id; }).no;
			item.customer_no='';
			
			item.workhour	= value;
			return item;
		});

		let works = itemWorks.filter(function(item){
			return item.group !== 0;
		});

		works = itemHoliday.concat(works);
		items = works.concat(items);		// works.concat(items);
		this.setState({
//			items: items,
			groups: groups,
//			visitList:	visitList,
			customerList: masterParam[MASTER_CUSTOMER].list,
		});
 		this.setState({items: items});		// 下の行とまとめてsetStateすると表示が更新されない
// console.log(items);
	}

// ======================================================================================
// 画面操作
// ======================================================================================

	// 予約管理ボタンが押された
	initButton = () => {
		this.setState({ stepDays: 1});
		this.changeDate(moment().tz(TIMEZONE));

	}

	// 日時が変更された
	changeDate = (date) => {
		if ( date === null ) return;
		let day = moment(date).format("YYYY-MM-DD");

		let start = moment(date).add(-7,'days').format('YYYY-MM-DD');
		let end   = moment(date).add( 7,'days').format('YYYY-MM-DD');
		this.getVisitTable(start,end,true);								// 予約データをvisitListに読み込む

		// 開店前に予約がある場合に、予約を表示する
		let stime = salon.stime;
		visitList.forEach((value, index, array) => {
			if ( value.visit_date === day ) {
				if ( value.visit_time < stime ) stime = value.visit_time;
			}
		});
		
		this.setState({ 
			viewDate: day,
//			visibleTimeStart: moment(day + ' ' + salon.stime).valueOf(),
			visibleTimeStart: moment(day + ' ' +       stime).valueOf(),
			visibleTimeEnd:   moment(day + ' ' + salon.etime).valueOf()
		});
	}

	/* ----------------------------------------------
	* Attendance が使用するコールバック 
	* 作成したシフトを表示する　開始日から1週間
	---------------------------------------------- */
	changePeriod = (date) => {
		let start = moment(date).add(-7,'days').format('YYYY-MM-DD');
		let end   = moment(date).add( 7,'days').format('YYYY-MM-DD');
		this.getVisitTable(start,end,true);								// 予約データをvisitListに読み込む

		const unixStart = moment(date).valueOf();
		const unixEnd   = moment(date).add( 7,'days').valueOf();
		this.setState({ 
			visibleTimeStart: unixStart,
			visibleTimeEnd:   unixEnd,

			viewDate: moment((unixStart+unixEnd) / 2).format('YYYY-MM-DD')
		});

	} 


	// 店舗を変更した <Select onChange={}/> 
	salonChange = (option) =>  {

		salon_id = Number(option.value);

		// ローカルストレージにsalon_idを書き込む
		let storeItem = LocalStorage.getItem();
		storeItem.salon_id = salon_id;
		LocalStorage.setItem(storeItem);
		
		salon = masterParam[MASTER_SALON].list.find((s) => s.id === salon_id);
		if ( salon === undefined ) {
			salon = masterParam[MASTER_SALON].list[0];
			salon_id = salon.id;
		}

		// stylist と VisitTable を読み込む
		data_flag = 0;
		this.getStylist(salon_id);

		let start = moment(this.state.viewDate).tz(TIMEZONE).add(-7,'days').format('YYYY-MM-DD');
		let end   = moment(this.state.viewDate).tz(TIMEZONE).add( 7,'days').format('YYYY-MM-DD');
//		tableItemStart = tableItemEnd = moment().tz(TIMEZONE).format('YYYY-MM-DD');
		this.getVisitTable(start,end,true);		// 前後1ヶ月分の予約・勤怠データを読み込む
	}

// ======================================================================================
// Visitのコールバック関数
// ======================================================================================

	// 削除ボタンが押された
	onDelete = (item) => {
		Confirm( "削除してよろしいですか？" ).then(result => {
			if ( ! result ) return;
//			if ( ! window.confirm( "削除してよろしいですか？" ) ) return;
			// eslint-disable-next-line
			switch(editStatus) {
				case EDIT_UNSELECT:
				case EDIT_SELECT:
				case EDIT_ADD:
					this.setState({showModal: false });
					break;
				case EDIT_DEL:
					// no break;
					// no-fallthrough
				case EDIT_REP:
					this.deleteTechSlip(item.id);
					this.deleteGoodsSlip(item.id);
					this.deleteVisit(item.id);
	
					this.setState({showModal: false });
					break;
			}
			editStatus = EDIT_UNSELECT;
		});
	}

	// Visitor画面で登録ボタンが押されたときに呼び出されるコールバック関数
	onClose = (item,techSlip,goodsSlip) => {

		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
			case EDIT_SELECT:
				break;
			case EDIT_ADD:
				editStatus = EDIT_UNSELECT;

				if ( item.visit.pay > 0 ) {
					let account = amount(item);
					if ( item.visit.pay >= account) {
						addPoint(item);
						if ( item.visit.visit_state < STATE_PAY ) {
							item.visit.visit_state = STATE_PAY;
						}
					} else	item.visit.visit_state = STATE_VISIT;
				}

				this.postVisit(item);			// 来店情報の追加 Add
				break;
			case EDIT_DEL:
				// ここは通らない。
				editStatus = EDIT_UNSELECT;
				/******** このシステムではデーターは削除しない
				 * 番号を 10000 + id に変更して、10000以上の番号は削除したとみなす。
				Request.del('/staff/' + rowProp.id )
					.end(function(err, res){
						console.log(res.body);
						this.getStaff();
					}.bind(this));
				***************************************************/
				item.no = MAX_DB_LENGTH + item.id;
				// no break;
				// no-fallthrough
			case EDIT_REP:
				editStatus = EDIT_UNSELECT;

				if ( item.visit.pay > 0 ) {
					let account = amount(item);
					if ( item.visit.pay >= account) {
						addPoint(item);
						if ( item.visit.visit_state < STATE_PAY ) {
							item.visit.visit_state = STATE_PAY;
						}
					} else 	item.visit.visit_state = STATE_VISIT;
				}
				this.putVisit(item);			// 来店情報の修正 Replace
//				this.onItemSelect(item.id);		// 2022-12-22 Close時にitem.titleが描き変わらないので再読み込み
				break;
		}
		// Request.put()より先に実行されてしまう。
		this.setState({
			rowData:item,
			techSlipList: techSlip,
			goodsSlipList: goodsSlip,
//		});
//		this.setState({
			showModal: false
		});
		editStatus = EDIT_SELECT;
	}

	// キャンセルボタンが押された
	onCancel = () => {
		// eslint-disable-next-line
		switch(editStatus) {
			case EDIT_UNSELECT:
			case EDIT_SELECT:
				break;
			case EDIT_ADD:
				editStatus = EDIT_UNSELECT;
				break;
			case EDIT_REP:
//				this.onItemSelect(this.state.rowData.id);
				break;
			case EDIT_DEL:
				editStatus = EDIT_UNSELECT;
				break;
		}

		this.setState({showModal: false });
	}
	
// ======================================================================================
// React TimeLine処理関数
// ======================================================================================

	// 予約・来店・勤務の追加
	onCanvasDoubleClick = (groupId, time, e) => {
		Alert('dobuleClick');
		this.onCanvasClick(groupId, time, e);
	}
// ======================================================================================
// React TimeLine処理関数
// ======================================================================================

	// 予約・来店・勤務の追加
	onCanvasClick = (groupId, time, e) => {
//		if ( this.state.customerList.length === 0 ) return;			// 顧客情報を読取る前なら return

		let item = Object.assign({},initialRow);
		let visit = {};
		let group = this.state.groups.find(function(o) { return o.id === groupId; });		// DB 項目の設定

		visit.id		= 0;			// DB登録後に決まる 仮に0としておく
		visit.salon_id  = salon_id;
		visit.staff_id	= group.id;		// 仮にグループIDとしておく
		visit.name		= "";
		visit.visit_date= moment(time).format('YYYY-MM-DD');
		visit.visit_time= moment(time).format('HH:mm');
		visit.duration	= 30;			// Default 30分
		visit.visit_state= STATE_BOOK;
		visit.customer_id= 0;
		visit.memo		 = '';			// メモ
		visit.credit_id  = 0;			// 現金
		visit.pay        = 0;
		visit.use_point  = 0;
		visit.add_point  = 0;
		item.visit       = visit;

		// 表示item項目の設定
		item.id			= visit.id;		// DB登録後に決まる 仮に0としておく
		item.group		= groupId;

		item.bookType	= BOOK_VISIT;
		item.title	    = visit.name + ( visit.nickname === null ? '' : ' 【' + visit.nickname + '】 ' )	// 技術略称を追加する
									 + ( visit.memo     === null ? '' : visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
		item.start_time	= time;
		item.end_time	= time + (visit.duration * 60000 );   // 分-> ミリ秒
		item.className	= itemClassName[visit.visit_state];

		// 編集項目の設定
		item.visit_time	= moment(time).format('YYYY-MM-DD HH:mm');
		item.customer_no	= '';
// console.log('onCanvasClick()',item);

		editStatus = EDIT_ADD;
		techSlip = [];									// 技術売上を初期化
		goodsSlip = [];									// 技術売上を初期化
		this.setState({	
			rowData: item,
			techSlipList: techSlip,			// 技術売上を初期化
			goodsSlipList: goodsSlip,		// 商品売上を初期化
			showModal: true,
		});
	}

	// 予約の変更　2回目のクリック
	onItemClick = (itemId) => {
		let item = this.state.items.find(function(obj) { return obj.id === itemId; });
		switch ( item.bookType ) {
			case BOOK_VISIT:
				this.onItemClickVisit(item);
				break;
			case BOOK_WORK:
				this.onItemClickWork(item);
				break;
			case BOOK_HOLIDAY:
			default:
				break;
		} 
	}
	
	onItemClickVisit = (item) => {
		editStatus = EDIT_REP;
		this.setState({
			showModal: true
		});

		this.getTechSlip(item.id);		// 技術売上を読み込む
		this.getGoodsSlip(item.id);		// 商品売上を読み込む
		this.getLog(item.id);			// 来店情報の編集ログを読み込む
	}

	onItemClickWork = (item) => {
		editStatus = EDIT_REP;
		this.setState({
			showModalNotifyEdit: true
		});

	}

	/* itemの選択を解除するときに呼び出される？ */
	onItemDeselect = (e) => {
//		console.log('onItemDeselect', e);
	}
	/* ----------------------------------------- */
	
	// 予約・勤怠の選択  1回目のクリック
	onItemSelect = (itemId) => {
		let item  = this.state.items.find(function(obj) { return obj.id === itemId; });
// console.log('onItemSelect',item, itemId);

		switch ( item.bookType ) {
			case BOOK_VISIT:
				this.onItemSelectVisit(item);
				break;
			case BOOK_WORK:
				this.onItemSelectWork(item);
				break;
			case BOOK_HOLIDAY:
			default:
				break;
			
		} 
	}

	// 来店情報itemの選択 １回目のクリック
	onItemSelectVisit = (obj) => {
// console.log('onItemSelectVisit',obj);
		let item = JSON.parse(JSON.stringify(obj));		// ディープコピー
		
		// visitの読み込み時に行う処理だが、visit X customerの処理は無駄なのでここに移動した。
		let customer_id = item.visit.customer_id;
		let cust = masterParam[MASTER_CUSTOMER].list.find(function(obj){ return obj.id === customer_id; });
		let customer = Object.assign({},cust);

		// コメント、ポイントの表示
		if ( cust === undefined ) {
			item.point   = 0;
			item.customer_no = 0;
			this.setState({
				rowData: item,
//				custNo: 0,
			});		
		} else {
			item.point       = customer.point;
			item.customer_no = customer.no;

			this.setState({
				rowData: item,
//				custNo: customer.no ,
			});	
		}

		editStatus = EDIT_REP;
		this.getTechSlip(item.id);		// 技術売上を読み込む
		this.getGoodsSlip(item.id);		// 商品売上を読み込む
		this.getLog(item.id);			// 来店情報の編集ログを読み込む
		
	}

	// item work が選択された 2023-08-02 作成
	onItemSelectWork = (obj) => {
/*
		let item  = Object.assign({},obj);			// 注意：itemは複製しても、item.workは複製してくれない
		item.workhour = Object.assign({},obj.workhour);		// item.workを複製する
 console.log('onItemSelectWork',obj,item);		
*/
// console.log(obj)
		this.setState({
			rowData: JSON.parse(JSON.stringify(obj)),		// ディープコピー
		});		

		editStatus = EDIT_REP;
	}

	// 日時の変更
	onItemMove = (itemId, dragTime, newGroupOrder) => {
		let item = this.state.items.find(function(obj) {return itemId === obj.id;});
		if ( !item ) return;
		this.setState({ 
			dragTime: dragTime,
			rowData:item			
		});
		this.setState({showModalMoveBook: true});
	}

	// ModalMoveBook のコールバック
	repVisit = ( item, dragTime ) => {
		if ( item ) {
			if ( item.bookType === BOOK_WORK ) return;
			item.visit.visit_date = moment(dragTime).format('YYYY-MM-DD');
			item.visit.visit_time = moment(dragTime).format('HH:mm');
//			item.title		= item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】' );
			item.title	    = item.visit.name + ( item.visit.nickname === null ? '' : ' 【' + item.visit.nickname + '】 ' )		// 技術略称を追加する
											  + ( item.visit.memo     === null ? '' : item.visit.memo.replace(/\\n/g, ' ') );	// メモを追加する
			item.start_time = dragTime;
			item.end_time   = dragTime + (item.visit.duration * 60000 );
			item.visit_time = moment(dragTime).format('YYYY-MM-DD HH:mm');
			this.putVisit(item);		// 来店情報のReplace・技術売上の書き込み・来店情報（全部）の再読み込み
		}
		this.setState( {showModalMoveBook: false });
	}

	// itemリサイズ
	onItemResize = (itemId,time,edge) => {
		let item = this.state.items.find(function(obj) {return itemId === obj.id;});
		item.end_time = time;
		item.visit.duration = (item.end_time - item.start_time) / 60000;
		if ( item.visit.duration  < 15 ) {
			item.visit.duration = 15;
			item.end_time = item.start_time + 15 * 60000;
		}

		this.putVisit(item);		// 来店情報のReplace・技術売上の書き込み・来店情報（全部）の再読み込み
	}

	// 横スクロール時にキャンバスの範囲を越えると呼び出される キャンバスはビューの３倍
	onBoundsChange = (canvasTimeStart,canvasTimeEnd) => {

		let start = moment(canvasTimeStart).format('YYYY-MM-DD');
		let end   = moment(canvasTimeEnd  ).format('YYYY-MM-DD');

		this.getVisitTable(start,end);  // データ範囲
		
	}
	
	// 横スクロール時に毎回呼び出される。サイズ変更時にも呼び出される。
	onTimeChange = (start, end, updateScrollCanvas) => {
		let range = 7*24*60*60*1000;
		let three = 3*24*60*60*1000;
		if ( end - start > range ) {		// 表示範囲は7日を超えてはならない
			if ( this.state.visibleTimeStart > start ) {
				start = moment(this.state.viewDate).format('x') - three;
				end = start + range;
			} else {
				start = end -range;
			}
		}
		this.setState({ 
					visibleTimeStart: start,
					visibleTimeEnd:   end,
					viewDate: moment((start+end) / 2).format('YYYY-MM-DD')
		});
		updateScrollCanvas(start, end);
	}
	
	onTimeInit = (visibleTimeStart, visibleTimeEnd) => {

	}

	noStepChange = (event) => {
		if ( isNaN( Number(event.target.value) ) ) return;
		this.setState({
			stepDays: event.target.value
		});
	}
	
	onIncDate = () => {
		let step = this.state.stepDays;
		if ( !step ) {
			this.setState({ stepDays: 1 });
			step = 1;
		}

		let day = moment(this.state.viewDate).add(step,'days');
		this.changeDate(day);
	}
	onDecDate = () => {
		let day = moment(this.state.viewDate).add(-1,'days');
		this.changeDate(day);
	}


/* React-Timeline End   ------------------------------------- */

	

	itemRenderer = ({
		item,
		timelineContext,
		itemContext,
		getItemProps,
		getResizeProps
	}) => {
		const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
		const backgroundColor = itemContext.selected ? itemContext.dragging ? "Orange" : item.selectedBgColor : item.bgColor;
//		const borderColor = itemContext.resizing ? "Orange" : item.color;
// console.log('itemRenderer',backgroundColor, itemContext, item );
		return (
			<div {...getItemProps({
			      style: {
			        backgroundColor,
			        color: item.color,
//			        borderColor: borderColor,
//			        borderStyle: "solid",
//			        borderWidth: 1,
			        borderRadius: 2,
			        borderLeftWidth: itemContext.selected ? 3 : 1,
			        borderRightWidth: itemContext.selected ? 10 : 1
			      },
			      onMouseDown: () => {
//			        console.log("on item click", item);
			      }
			    })}
			>
				{itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
		
				<div style={{
						height: itemContext.dimensions.height,
						overflow: "hidden",
						paddingLeft: 3,
						textOverflow: "ellipsis",
						whiteSpace: "nowrap"
					}}
			    >
				{itemContext.title}
		    </div>
				{itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
		  </div>
		);
	};


	formatLabel = (
		[timeStart, timeEnd],
		unit,
		labelWidth,
		formatOptions = headerFormats
	) => {
		let format;
		if (labelWidth >= 150) {
			format = formatOptions[unit]['long'];
		} else if (labelWidth >= 100) {
			format = formatOptions[unit]['mediumLong'];
		} else if (labelWidth >= 50) {
			format = formatOptions[unit]['medium'];
		} else {
			format = formatOptions[unit]['short'];
		}
		return timeStart.format(format);
	}

/* Stylist -------------------------------------------------- */
	onClickMonthRoster = () => {
		this.setState({
			showModalRoster: true,
		});
	}
	onRosterToggle = () => {
	    this.setState( {
	    	showModalRoster: ! this.state.showModalRoster
	    } );

	}

	onClickStylist = () => {
		this.toggleStylist();
	}
	cancelStylist = () => {
		this.setState({ showModalStylist:false });
		this.getStylist(salon_id);
	}
	closeStylist = () => {
		this.setState({ showModalStylist:false });
		this.putStylistList(salon_id);
	}
	toggleStylist = () => {
		this.setState({ showModalStylist: !this.state.showModalStylist });
	}
	onStylistChange = (stylistList) => {
		stylist = 	stylistList;
	}

/* Stylist -------------------------------------------------- */

/* 勤怠管理 ------------------------------------------------- */
	notifyOnClose = (workhour) => {

		this.setState({
			showModalNotifyEdit: false,
		});
		editStatus = EDIT_SELECT;
	}
	
	notifyOnCancel = () => {
		switch(editStatus) {
			case EDIT_UNSELECT:
			case EDIT_SELECT:
				break;
			case EDIT_ADD:
				editStatus = EDIT_UNSELECT;
				break;
			case EDIT_REP:
//				this.onItemSelect(this.state.rowData.id);
				break;
			case EDIT_DEL:
				editStatus = EDIT_UNSELECT;
				break;
			default:
				break;
		}

		this.setState({
			showModalNotifyEdit: false
		});
		
	}
	notifyOnDelete = () => {
		this.setState({
			showModalNotifyEdit: false
		});
		editStatus = EDIT_UNSELECT;
	}
//						onClose={this.notifyOnClose} onCancel={this.notifyOnCancel} onDelete={this.notifyOnDelete} />
/* 勤怠管理 ------------------------------------------------- */


/* ToolTip -------------------------------------------------- */
	toggle = () => {
		this.setState({tooltipOpen:!this.state.tooltipOpen});
	}
	
/* ToolTip -------------------------------------------------- */


	render() {
// console.log('Book render()',this.state.rowData);
		moment.locale('ja');

		// react-datetime 日曜日を開始にしたいが上手くいかず
		moment.updateLocale("ja", { week: {
			dow: 0, // First day of week is Sunday
		}});


		const tsteps = {
		  second: 1,
		  minute: 15,
		  hour: 1,
		  day: 1,
		  month: 1,
		  year: 1
		};
		
		var sidebarContent = React.createElement("div", {}, "スタイリスト");


		// 表示日の変換
		let viewDate = new Date(this.state.viewDate);
		
		// 国民の休日
		const highlightDates = [
			{
				"react-datepicker__day--highlighted-custom-1": holidays
			},
		];
		
		// 曜日ごとのクラス
		let dayClassName = [
			'form-control sunday-text',
			'form-control ',
			'form-control ',
			'form-control ',
			'form-control ',
			'form-control ',
			'form-control saturday-text',
			][viewDate.getDay()];

		if ( holidays.find(element => element.getTime() === viewDate.getTime() ) ) {
			dayClassName = 'form-control holiday-text';
		}
		
		// 店舗の設定
		let salonList = this.state.salonList.filter( (salon) => {		// 削除された番号は選択出来ない
			return salon.no < MAX_DB_LENGTH;
		});
		let options = salonList.map( (salon) => {
			return { value: salon.id, label:salon.no + ' ' + salon.name.trim()};
		});
		let selectedSalon = this.state.salonList.find( (salon) => {
			return salon.id === salon_id;
		});
		let salonValue = { value: '', label:''};
		if ( selectedSalon !== undefined ) {
			salonValue = { value: selectedSalon.id, label: selectedSalon.name.trim()};
		}
		
		let staff = this.state.staffList.find( (value) => {
			return value.id === this.state.rowData.group;
		});
		
		let staffName = "";
		if ( staff ) staffName = staff.name; 
		
		const dropdown =
				<div>
					<DropdownItem divider />
					<DropdownItem onClick={this.onClickMonthRoster }>
                    	勤怠集計表印刷
                	</DropdownItem>

                	<DropdownItem onClick={this.onClickStylist }>
                    	スタイリスト設定
                	</DropdownItem>
				</div>;

		return (
			<div >
				<ButtonToolbar><ButtonGroup>
					<InputGroup style={{width:220}} >
						<Button  onClick={this.initButton} id="BookToday">日付</Button>
						<Tooltip placement="right" isOpen={this.state.tooltipOpen} target="BookToday" toggle={this.toggle}><FaInfoCircle/>今日の予約を表示</Tooltip>
						<Button  onClick={this.onDecDate} id="BookBefore"><FaArrowLeft/></Button>
						<DatePicker className={dayClassName} selected={viewDate} onChange={this.changeDate} locale="ja" dateFormat="yyyy-MM-dd" fixedHeight style={{width:80}} highlightDates={highlightDates} />
					</InputGroup>
				</ButtonGroup><ButtonGroup>
					<InputGroup style={{width:90}} >
						<Input type="number" value={this.state.stepDays} onChange={this.noStepChange}/>
						<Button  onClick={this.onIncDate} ><FaArrowRight/></Button>
					</InputGroup>{'　'}
				</ButtonGroup><ButtonGroup>
					<InputGroup style={{width:200}} >
						<InputGroupText>店舗</InputGroupText>
						<Select className="form-control react-select-original" placeholder="店舗名" value={salonValue} options={options} onChange={this.salonChange} />

					</InputGroup>
					<CashCount salon_id={Number(salon_id)} salon={salon} viewDate={this.state.viewDate} changeDate={this.changeDate} staffList={this.state.staffList} customerList={this.state.customerList}/>
				</ButtonGroup>
{/*					<div style={{width:'100px'}}><HelpPopover id='booking' help={help} style={{width:'100%'}} /></div> */}
					<HelpPopover id='booking' help={help} />
				</ButtonToolbar>
				<Timeline className="schedule" id='TBL' groups={this.state.groups} items={this.state.items} timeSteps={tsteps}
						sidebarWidth={120} lineHeight={50} itemHeightRatio={0.5} dragSnap={15*60*1000} canResizeRight={true} fixedHeader="fixed"
						stackItems={true} canChangeGroup={false}  clickTolerance={10} // itemTouchSendsClick={true} // bug? 他のイベントまで取り込んできまう
						defaultTimeStart={moment().tz(TIMEZONE).add(-3, 'hour')}  defaultTimeEnd={moment().tz(TIMEZONE).add(5, 'hour')}
						visibleTimeStart={this.state.visibleTimeStart}  visibleTimeEnd={this.state.visibleTimeEnd}
						sidebarContent={sidebarContent}
						onCanvasClick={this.onCanvasClick}
						onCanvasDoubleClick={this.onCanvasDoubleClick}
						onItemClick={this.onItemClick}
						onItemSelect={this.onItemSelect}
						onItemDeselect={this.onItemDeselect}
						onItemMove={this.onItemMove}
						onItemResize={this.onItemResize}
						onBoundsChange={this.onBoundsChange}
//						onTimeInit={this.onTimeInit}
						onTimeChange={this.onTimeChange}
						itemRenderer={this.itemRenderer}
//						stickyHeader={false}					stickyの機能は無くなった。
				>
				    <TodayMarker />
				    <TodayMarker>
					  {({ styles, date }) =>
					    // date is value of current date. Use this to render special styles for the marker
					    // or any other custom logic based on date:
					    // e.g. styles = {...styles, backgroundColor: isDateInAfternoon(date) ? 'red' : 'limegreen'}
					    <div style={{...styles, backgroundColor: 'crimson' }} />
					  }
					</TodayMarker>
					<CursorMarker />
					<TimelineHeaders className="sticky">
						<SidebarHeader>
					      {({ getRootProps }) => {
					        return (
					        	<div {...getRootProps()} style={{width:120, color: 'white',textAlign: 'center' }}>
					        		<br/>
					        		<CreateShift name={'シフト・勤怠'}
										reload={this.changePeriod} 
										salon={salon}
										getBookInfo={this.getBookInfo}
										dropdown={dropdown}
									/>
{//									<Button color='transparent' style={{ color:'white', fontSize:'14px' }} onClick={this.onClickStylist}>スタイリスト</Button>
}
					        	</div>
					        );
					      }}
						</SidebarHeader>
					    <DateHeader labelFormat={this.formatLabel} unit="primaryHeader" height={36}   
//					    	intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
//					    	console.log(intervalContext);
//						        return <div {...getIntervalProps()}>
//						          {intervalContext.intervalText}
//						        </div>
//						    }}
						/>
					    <DateHeader labelFormat={this.formatLabel} height={36}
//					    	intervalRenderer={({ getIntervalProps, intervalContext, data }) => {
//					    	console.log(intervalContext);
//						        return <div {...getIntervalProps()}>
//						          {intervalContext.intervalText}
//						        </div>
//						    }}
						/>
					</TimelineHeaders>
				</Timeline>
				
				<Visitor showModal={this.state.showModal}
					item={this.state.rowData} 
					salon={salon}
					customerList={this.state.customerList}
					techList={this.state.techList}
					goodsList={this.state.goodsList}
					staffList={this.state.staffList}
					creditList={this.state.creditList}
					techSlipList={this.state.techSlipList}
					goodsSlipList={this.state.goodsSlipList}
					taxList={masterParam[MASTER_TAX].list}
					pointList={masterParam[MASTER_POINT].list}
					onDelete={this.onDelete}
					onCancel={this.onCancel}
					onClose={this.onClose}
					onPutCustomer={this.putCustomer}
					itemRenderer={this.itemRenderer}
				/>

				<Modal isOpen={this.state.showModalStylist} backdrop={'static'} size={'sm'} onClose={this.cancelStylist} autoFocus={false}> 
					<ModalHeader toggle={this.toggleStylist}>スタイリスト設定</ModalHeader>
					<ModalBody>
						<Stylist staffList={this.state.staffList} stylistList={stylist}
								onChange={this.onStylistChange} salon_id={salon_id} />
					</ModalBody>
					<ModalFooter>
						<Button color="warning" onClick={this.cancelStylist}>キャンセル</Button>
						<Button color="primary" onClick={this.closeStylist} >OK</Button>
					</ModalFooter>
				</Modal>
				<ModalMoveBook modal={this.state.showModalMoveBook} dragTime={this.state.dragTime} item={this.state.rowData} staffList={this.state.staffList} repVisit={this.repVisit} />
				<ModalNotifyEdit isOpen={this.state.showModalNotifyEdit} title='勤怠届' subTitle={staffName} workhour={this.state.rowData.workhour} dayOffItem={this.state.dayOffItemList} 
						onClose={this.notifyOnClose} onCancel={this.notifyOnCancel} onDelete={this.notifyOnDelete} reload={this.changeDate} />
						
				<Modal isOpen={this.state.showModalRoster} backdrop={'static'} size="lg" autoFocus={false}>
					<ModalHeader toggle={this.onRosterToggle}>{"勤怠 月次集計表"}</ModalHeader>
					<ModalBody>
						<MonthRoster />
					</ModalBody>
					<ModalFooter>
			            <Button color="secondary" onClick={this.onRosterToggle}>終了</Button>
					</ModalFooter>
    			</Modal>


			</div>
		);
	}
}

// Markdown に表示するヘルプ
const help = 
	"##### 概要\n" +
	"複数店舗の " +
	"<span style='background-color: lightcyan; border-width:1px solid #ccc '>予約</span>・" + 
	"<span style='color: white; background-color: SteelBlue; '>来店</span>・" + 
	"<span style='color: white; background-color: DarkBlue;  '>会計</span>・" +
	"<span style='color: white; background-color: dimgray; '  >集計</span>の状況を表示/変更できます。" +
	"また、スタイリストの" +
	"<span style='background-color: honeydew; border-width:1px solid #ccc ' >勤務予定</span>を表示します。  \n" +
	"（[勤怠管理](./#/workhour)を参照）  \n" +
	"**スワイプ**/**ピンチ**で表示位置/拡大縮小を変更できます。  \n" +
	"##### 日付\n" +
	"本日の開店時間から閉店時間までの予約項目を表示します。  \n" +
	"##### ◀年-月-日　日数︎▶︎   \n" +
	"　◀︎：前日を表示します。  \n" +
	"　年-月-日：指定した日を表示します。︎  \n" +
	"　日数▶︎：日数分先の**予約項目**を表示します。︎  \n" +
	"##### 店舗\n" +
	"現在選択されている店舗の予約項目を表示します。  \n" +
	"起動時は最後に表示されていた店舗を表示します。  \n" +
	"店舗の追加/変更/削除は **設定登録**>[店舗](./#/salon)で行います。 \n" +
	"##### 店舗集計\n" +
	"店舗別の集計を行います。  \n" +
	"###### 　レジ入出金  \n" +
	"現金実査を行う為にレジ内の現金収支を記録します。  \n" +
	"勘定科目の追加/変更/削除は**設定登録**>[勘定科目](./#/accountitem)で行います。  \n" +
//	"レシート等は保存しておく事。  \n" +
	"###### 　日時集計  \n" +
	"店舗の１日の売上明細と集計を表示/印刷できます。  \n" +
	"###### 　月次集計  \n" +
	"店舗の１ヶ月の売上を集計して表示/印刷できます。  \n" +
	"###### 　年次集計  \n" +
	"店舗の１年間の売上を集計して表示/印刷できます。  \n" +
	"##### 予約表\n" +
	"**スワイプ**で左右に移動できます。  \n" +
	"**ピンチ**で拡大縮小できます。  \n" +
	"マウス利用時は  \n" +
	"**shift** + **マウスホイール**で 左右に移動します。  \n" +
	"**alt** + **マウスホイール** で拡大縮小できます。  \n" +
	"**ctrl** + **マウスホイール** で拡大縮小 10倍速。  \n\n" +

	"15分単位で予約できます。  \n" +
	"###### 　スタイリスト\n" +
	"担当者の一覧を表示し予約表に表示するスタイリストを選択できます。  \n" +
	"担当者の編集は[担当者](./#/staff)を参照  \n" +
	"###### 　年月日　曜日 \n" +
	"タップすると週単位の表示になります。  \n" +
	"###### 　時分 \n" +
	"タップすると15分単位の表示になります。  \n" +
	"###### 　現在時刻\n" +
	"赤い縦線は現在時刻を示します。  \n" +
	"###### 　予約項目の表示\n" +
	"スタイリストと予約日時が交差する位置に**予約項目**を表示します。  \n" +
	"**予約項目**には**お客様名**/**技術略称**/**メモ**を表示します。  \n" +
	"**予約項目**の幅は**技術**の**施術時間**の合計時間を示し、技術が未入力の場合は30分とします。  \n" +
	"一人のスタイリストで、同一時間に複数の予約項目を追加可能です。  \n" +
	"<span style='background-color: lightcyan; border-width:1px solid #ccc '>予約</span>・" + 
	"<span style='color: white; background-color: SteelBlue; '>来店</span>・" + 
	"<span style='color: white; background-color: DarkBlue;  '>会計</span>・" +
	"<span style='color: white; background-color: dimgray; '  >集計</span>・" +
	"<span style='background-color: honeydew; border-width:1px solid #ccc ' >勤務予定</span>をそれぞれ色分けして表示します。  \n" +
	"**技術略称**、**施術時間**の変更は**設定登録**>[技術](./#/tech)で行います。  \n" +
	"**お客様名**、**メモ**の変更は予約画面(予約の変更)で行います。  \n" +
	"###### 　予約項目の追加\n" +
	"スタイリストと予約日時が交差する点をタップする。**予約項目**画面を表示します。  \n" +
	"**予約項目**画面には選択したスタイリストと時間が表示されます。" +
	"また、**予約項目**画面でもスタイリストや予約時間を変更できます。  \n" +
	"**予約項目**画面でお客様/技術/商品/会計情報/メモを入力できます。  \n" +
	"###### 　予約項目の変更\n" +
	"予約項目を選択後にもう一度タップすると**予約項目**画面を表示します。  \n" +
	"**予約項目**画面でお客様/技術/商品/会計情報/メモを入力できます。  \n" +
	"";


export default Booking;


