// UserOnly.js
import React, { Component } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import GuestNavBar from '../GuestNavBar';
import { JWToken } from './GuestLogin';


export default class GuestOnly extends Component {
/* 

//  static contextTypes = {
//    router: PropTypes.object.isRequired
//  }

  componentWillMount() {
//  this.guestWillTransfer(this.props, this.context.router);
    this.guestWillTransfer(this.props);
  }

  componentWillUpdate(nextProps) {
//  this.guestWillTransfer(nextProps, this.context.router);
    this.guestWillTransfer(nextProps);
  }

  guestWillTransfer(props) {
    console.log('GuestOnly');
    if (JWToken.getToken().isLoggedIn) {
      console.log('isLoggedIn:true');
//      router.replace('/');
    }
  }
*/

  render() {
    if (JWToken.getToken().isLoggedIn) {
      console.log('GuestOnly ' + this.props.children );
      return (
          <div>
              <GuestNavBar />
              <Container fluid={true}>
                  <Route children={this.props.children} />
              </Container>
          </div>
      );
    } else {
      console.log('/guest/' + this.props.match.params.tenant);
      return <Navigate to={'/guest/' + this.props.match.params.tenant } />;
    }
  }
}

