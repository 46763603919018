import React, { Component } from 'react';

import { Media, Container, Button } from 'reactstrap';
// import { GuestNavBar } from './auth/Login';
import { BsClockHistory } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

const NewsDiscription = () => {
  const navigate = useNavigate();

  const move = () => {
    navigate('/login'); // 画面遷移
  };

  return (
    <Container >
      <Button color="primary" onClick={move} >戻る</Button>
      <Media className="mt-1">
        <Media left bottom href="#">
          2024-09-12
        </Media>
        <Media body>
          <Media heading>
            「シフト管理」を追加した
          </Media>
          ・予約画面の「シフト・勤怠」をクリックするとシフト管理/勤怠管理のプルダウンメニューが表示される<br/>
          ・「基本シフト」はシフトを簡単に作成する為に1週間分の基本シフトを作成する画面を表示する<br/>
          ・「シフト作成」はプルダウンメニューの一番上に表示されたシフト作成日から１週間のシフトを基本シフトからコピーする　ただし既に登録されている勤怠届は上書きしない<br/>
          ・「シフト要望の確認」はシフト作成日より前に提出されている勤怠届を確認する<br/>
          ・「シフト確定」はシフト作成日より前のシフトを確定させる<br/>
          ・「勤怠集計表」は担当者の月次集計表を印刷する<br/>
          ・「スタイリスト設定」は予約表に表示する担当者を選択する<br/>
       </Media>

        <Media body>
          <Media heading>
            「勤怠管理」を修正した
          </Media>
          ・「勤怠管理」は予約管理画面に移動した。勤怠の編集は予約画面で行う<br/>
          ・予約管理画面の「担当者」をクリックすると「出勤」、「退勤」、「諸届」のプルダウンメニューを表示する<br/>
          ・「出勤」は今日の出勤を打刻する<br/>
          ・「退勤」は今日の退勤を打刻する<br/>
          ・「諸届」は今後の休暇や出勤など、基本シフトと異なる場合に要望を記録する<br/>
       </Media>
      </Media>
      
      <Media className="mt-1">
        <Media left bottom href="#">
          2022-09-04
        </Media>
        <Media body>
          <Media heading>
            QRコードでスマートフォンのカレンダーに登録、履歴から技術項目をコピー
          </Media>
          ・予約項目画面で「QRコード」ボタンをクリックするとQRコードを表示する<br/>
          　お客様のスマートホンで QRコードを取り込んでください。<br/>
          　スマートフォンのカレンダーに ご予約を登録できます。<br/>
          ・来店履歴画面で「技術コピー」ボタンをクリックすると<br/>
          　表示している履歴の技術が予約画面にコピーされる。<br/>
       </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2022-03-21
        </Media>
        <Media body>
          <Media heading>
            時間外の予約に合わせて予約を表示する
          </Media>
          ・時間外の予約に合わせて、開始時間と終了時間を変更して予約管理を表示する<br/>
       </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-12-05
        </Media>
        <Media body>
          <Media heading>
            ネット予約を追加した。レシートにQRコードを印刷。
          </Media>
          ・予約メールを取得して「予約連絡」を登録する。<br/>
          ・レシートプリンターに登録されている画像2をレシートの最後に印刷する。<br/>
       </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-09-05
        </Media>
        <Media body>
          <Media heading>
            名前を40文字とした。削除した項目は一覧表で「削除」と表示。お客様検索画面の表示を高速化。
          </Media>
          ・顧客名、担当者名を最大40文字に変更した<br/>
          ・予約項目画面からお客様の検索画面を起動する時の高速化<br/>
          ・顧客、決済サービス、担当者、技術、技術集計、商品、仕入先、勤怠届、勘定科目で削除した項目は番号欄に「削除」と表示<br/>
          ・3年以上前のデータを削除<br/>
       </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-08-18
        </Media>
        <Media body>
          <Media heading>
            商品・材料の棚卸を追加。
          </Media>
          ・各店舗ごとの商品在庫数一覧表示を追加<br/>
          ・各店舗ごとの商品在庫数の初期化を追加<br/>
          ・各店舗ごとに商品在庫数の入力画面を追加<br/>
          ・各店舗ごとの商品在庫数一覧と資産の印刷を追加<br/>
          ・全店舗合計の商品在庫数一覧表示を追加<br/>
          ・全店舗合計の商品在庫数一覧と資産の印刷を追加<br/>
       </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-08-01
        </Media>
        <Media body>
          <Media heading>
            月次印刷、年次印刷で技術集計、商品集計を印刷
          </Media>
          ・店舗の月次集計印刷に技術、商品の集計ページを追加<br/>
          ・店舗の年次集計印刷に技術、商品の集計ページを追加<br/>
          ・全店舗の月次集計印刷に技術、商品の集計ページを追加<br/>
          ・全店舗の年次集計印刷に技術、商品の集計ページを追加<br/>
       </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-07-29
        </Media>
        <Media body>
          <Media heading>
            最初の項目にフォーカスを移動
          </Media>
          ・ダイアログを開いたときに最初の入力項目にフォーカスを移動させた<br/>
          ・iPadでは年次集計表の日付がInvalid Dateになっていたのを修正<br/>
        </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-07-12
        </Media>
        <Media body>
          <Media heading>
            予約管理の修正履歴を予約ログに残す
          </Media>
          ・予約管理の登録時に予約ログをDBに保存<br/>
          ・予約項目画面を操作している担当者「レジ担当」の入力を必須とする<br/>
          ・予約項目画面に予約ログ画面表示ボタン<BsClockHistory/>を追加<br/>
          ・予約ログを閲覧することで、いつ、誰が、何を修正したか確認できる<br/>
          ・予約項目画面で10円未満の計算方法を修正し、他の計算方法と統一<br/>
          ・ライブラリを最新に変更<br/>
        </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="/LINE/test">
          2021-05-21
        </Media>
        <Media body>
          <Media heading>
            ホームページを作成
          </Media>
          ・ホームページを作成<br/>
          ・レジ現金出納帳→レジ集計に変更<br/>
          ・Newsを追加<br/>
          ・ライブラリを最新に変更<br/>
          ・勤怠管理で勤務時間を法定労働時間の８時間としていたが、所定労働時間に変更<br/>
        </Media>
      </Media>
      <Media className="mt-1">
        <Media left bottom href="#">
          2021-05-01
        </Media>
        <Media body>
          <Media heading>
            hairaccessの一般公開
          </Media>
          hairaccessを一般に公開<br/>
        </Media>
      </Media>
    </Container>
  );
};

class News extends Component {
  render() {
    return (
      <div className="About">
{/*     <GuestNavBar/> */}
        <NewsDiscription/>
      </div>
    );
  }
}

export default News;
